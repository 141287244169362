@import "./variable.scss";
@import "./mixins.scss";

html,
body {
	// background-color: $base-color-dark;
	color: $text-color;
	font-size: $font-size-m;
	// font-family: "Inter", sans-serif;
	font-family: $font-family-body;
	scroll-behavior: smooth;
	background-color: $base-color-dark;
}

header {
	--headerPadding: 8px;
}

a {
	color: $text-color !important;
	text-decoration: none;
	transition: all 0.3s;
}
a:hover {
	color: #a4a4a4 !important;
}
main {
	z-index: 2 !important;
	position: relative;
}

img {
	width: 100%;
}

.icon-aws {
	font-size: inherit;
	text-align: center;
}

.font-title {
	font-family: $font-family-title;
	font-weight: 400;
	font-style: normal;
}

.container {
	width: 100%;
	background-color: $base-color-dark;
	position: relative;
	padding-top: $header-heigth;
}

.section {
	max-width: 1350px;
	margin: 0 auto;
	padding: 20px 0;
	position: relative;
}

.section-half {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	div {
		width: calc(50% - 15px);
	}
}

//modale che appare al click del bottone
.header-modal {
	position: fixed;
	top: var(--headerPadding);
	left: var(--headerPadding);
	background-color: rgba(11, 11, 11, 0.4);
	backdrop-filter: blur(15px);
	display: flex;
	flex-direction: column;
	height: calc(100% - var(--headerPadding) * 2);
	width: 300px;
	z-index: 1000;
	padding: $header-heigth 34px 34px;
	border-radius: 15px;
	box-sizing: border-box;
	row-gap: 12px;
	overflow: hidden scroll;
	text-transform: capitalize;
	transition: all 0.5s;
	transform: translateX(-200%);

	&.open {
		transform: translateX(0);
	}

	hr {
		border: 0.5px solid $grey;
		width: 100%;
	}

	a,
	button,
	&__title {
		font-family: $font-family-title;
	}

	&__title {
		font-size: $font-size-s;
		color: $grey;
	}

	> button {
		width: fit-content;
		height: fit-content;
		font-size: inherit;
		background: transparent;
		border: none;
		color: $grey;
		transition: all 0.5s;
		cursor: pointer;
		padding: 0;
		text-align: left;

		&.it:hover,
		&.selected.it {
			background: linear-gradient(90deg, #008c45, #f4f9ff, #cd212a);
		}
		&.es:hover,
		&.selected.es {
			background: linear-gradient(0deg, rgb(198, 11, 30), rgb(241, 191, 0), rgb(198, 11, 30));
		}
		&.en:hover,
		&.selected.en {
			background: linear-gradient(90deg, #fff, #c8102e, #012169);
		}

		&.selected.it,
		&.selected.es,
		&.selected.en,
		&.it:hover,
		&.es:hover,
		&.en:hover {
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
}

.text-overlay {
	position: relative;

	span {
		font-size: 22vw;
		font-weight: 800;
		display: inline-block;
		text-shadow: -0.08em 0.03em 0.12em #000;

		&:not(:first-child) {
			margin-left: -0.15em;
		}
	}
}

.section-title {
	font-size: $font-size-xxl;
	font-weight: 500;
	text-transform: uppercase;
	width: fit-content;
	margin-bottom: 4px;
}

.pages-title {
	position: sticky;
	top: 0;
	z-index: 99;
	margin: 24px 0 16px;
	text-transform: capitalize;
	text-align: center;
	backdrop-filter: blur(5px);
	background-color: rgba(11, 11, 11, 0.3);

	&__title {
		font-size: $font-size-xxl;
	}
	&__subtitle {
		width: 100%;
		margin-top: 16px;
		padding: 4px 0;
		font-size: $font-size-s;
		color: $grey;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	// &:stuck {
	// 	.pages-title__subtitle {
	// 		margin-top: 8px;
	// 		background-color: rgba(0, 0, 0, 0.2);
	// 		backdrop-filter: blur(8px);
	// 		padding: 10px 0;
	// 	}
	// }
}

*.hidden {
	display: none;
	opacity: 0;
	visibility: hidden;
}
*.disabled {
	opacity: 0.8;
	cursor: default;
}

@include screens(larger-desktop) {
	.section {
		max-width: 1160px;
	}
}
@include screens(large-desktop) {
	.section {
		max-width: 980px;
	}
}
@include screens(desktop) {
	.section {
		max-width: 748px;
	}
}
@include screens(tablet) {
	.hideIfMobile {
		display: none;
	}
	.section {
		max-width: 570px;
		> div {
			width: calc(100% - 40px);
			margin-left: 20px;
		}
	}

	.section-half {
		flex-direction: column;
		justify-content: space-between;

		> div {
			margin: 10px 0;
			width: 100%;
		}
	}

	.header-modal {
		width: calc(100% - var(--headerPadding) * 2);
		height: calc(100% - var(--headerPadding) * 2 - $header-heigth);
		transform: translateX(0);
		transform: translateY(200%);
		left: calc(var(--headerPadding) * 1);
		top: calc(var(--headerPadding) * 0.5);

		&.open {
			transform: unset;
			padding-top: 24px;
			transform: translateY(0);
			padding-bottom: calc($logo-size + 30px);
		}
	}

	.container {
		padding-top: 0;
	}

	.pages-title {
		top: 0;
		margin: 8px 0 16px;

		&__title {
			font-size: $font-size-xxl;
		}
		&__subtitle {
			margin-top: 4px;
			padding: 4px 0;
		}
	}

	.leng-buttons {
		display: none;
	}
}
@include screens(phone) {
}
