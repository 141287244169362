@import "../../../scss/variable.scss";
@import "../../../scss/_mixins.scss";

.graph-bar-chart {
	--distance-inside-graph: 12px;
	--title-width: 45px;
	--bar-single-unit-width: 0px;

	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	&__titles {
		margin-bottom: 16px;
		.title {
			font-size: $font-size-xl;
			font-weight: 700;
			text-transform: capitalize;
		}
		.subtitle {
			font-size: $font-size-m;
			color: $grey;
		}
	}

	&__bars {
		.bar {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: nowrap;

			&__title {
				font-weight: 600;
				padding-top: 4px;
				padding-right: calc(var(--distance-inside-graph) - 4px);
				border-right: 1px solid $grey;
				width: var(--title-width);
			}
			&__solid {
				--bar-width: 0px;
				margin-left: var(--distance-inside-graph);
				height: 100%;
				min-height: 10px;
				background-color: $grey;
				// width: var(--bar-single-unit-width);
				width: 0px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;

				&.bar-animation {
					// width: 0px;
					// animation: startWidth 1s forwards;
					animation: startWidth 1s forwards;

					@keyframes startWidth {
						to {
							width: var(--bar-single-unit-width);
						}
					}
				}
			}
			&__number {
				opacity: 0;
				margin-left: 8px;
				font-size: $font-size-s;
				transition: all 0.5s;
			}

			&:last-child .bar__solid {
				@include bg-gradient-maker($orange, $yellow, to right);
			}

			&:hover {
				.bar__solid {
					transition: all 0.5s;
					@include bg-gradient-maker($orange, $yellow, to right);
				}
			}
		}
	}

	.bar-scale {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;
		margin-left: calc(var(--title-width) + 8px);
		border-top: 1px solid $grey;
		box-sizing: border-box;
		font-size: $font-size-s;

		&__container {
			position: relative;
			&:first-child {
				margin-left: var(--distance-inside-graph);

				.bar-scale__number {
					margin-left: 0;
				}
			}
		}

		&__line {
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 5px;
			background-color: $grey;
		}

		&__number {
			padding-top: 8px;
			width: var(--bar-single-unit-width);
		}
	}
}

@include screens(phone) {
	.graph-bar-chart {
		--title-width: 35px;

		&__titles {
			.title {
				font-size: $font-size-l;
				text-align: center;
				font-weight: 600;
			}
		}

		&__bars {
			overflow-x: scroll;
			.bar {
				&__title {
					font-weight: 300;
					font-size: $font-size-s;
					padding-right: 3px;
					border-right: none;
				}
				&__solid {
					margin-left: 0;
					margin-top: 4px;
				}
			}
		}
		.bar-scale {
			margin-left: var(--title-width);

			&__container {
				&:first-child {
					margin-left: 3px;

					.bar-scale__number {
						margin-left: 0;
					}
				}
			}
		}
	}
}
