@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

footer {
	position: relative;
	width: 100%;
	height: fit-content;
	// background-color: $base-color-dark;
	// background-color: rgba(0, 0, 0, 0.3);
	// background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.3));
	background: linear-gradient(transparent, #91e4e555);
	backdrop-filter: blur(25px);
	z-index: 2;
	display: flex;

	.footer__column {
		width: 33%;
		height: fit-content;
		margin: 16px 0 24px 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		.link_logo {
			.logo_image {
				width: $logo-size * 2;
				margin-bottom: 20px;
			}
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
	footer {
		flex-direction: column;
		.footer__column {
			margin-top: 0;
			width: 100%;
		}
	}
}
