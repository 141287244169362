@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $background-color-light;
	width: 100%;
	font-family: $font-family-title;
	padding: 4px 6px;
	border-radius: 15px;
	box-sizing: border-box;

	.search-icon {
		color: $base-color-dark;
		padding-right: 4px;
		cursor: pointer;
	}
	.search-input {
		background-color: transparent;
		border: none;
		color: $base-color-dark;
		height: 100%;
		font-size: $font-size-s;

		&:focus {
			outline: none;
			color: $red;
		}
	}

	&:has(.search-input:focus) {
		.search-icon {
			color: $red;
		}
	}

	&.input-container--dark {
		background-color: $background-color-dark;
		border: 1px solid $grey;

		.search-icon {
			color: $base-color-light;
		}
		.search-input {
			color: $base-color-light;
		}

		&:has(.search-input:focus) {
			border-color: $light-blue;
			.search-icon,
			.search-input {
				color: $light-blue;
			}
		}
	}
}
