@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.about-me {
	margin-top: $header-heigth;

	&__background {
		width: 100%;
		height: 200px;
		background-image: url("../../images/photos/2024_it_valDOrcia6_siena.jpg");
		background-size: cover;
		background-position: center;
		color: transparent;
		font-size: 100px;
		font-weight: 800;
		background-clip: text;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&__perfil {
		display: flex;
		flex-direction: column;
		align-items: center;

		gap: 50px;

		.card_perfil {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			// overflow: hidden;
			gap: 24px;

			&__image {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;
				color: $text-color;
				font-size: $font-size-s;
				width: 300px;

				overflow: hidden;
				transition: all 0.3s;

				&:hover {
					transform: scale(1.05);
				}

				img {
					height: 100%;
					border-radius: 16px;
					transform: scale(-1, 1);
				}
			}
			&__text {
				width: 50%;
				// text-wrap: balance;

				p:first-child {
					margin-top: 0;
				}

				.maps {
					border-radius: 16px;
					overflow: hidden;
					width: 100%;
					height: 200px;

					iframe {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.about-me__counters {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 24px;
		}
	}
	&__social {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;

		.social-element {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;
		}
		img {
			width: 40px;
		}
		span {
			font-size: $font-size-s;
		}
	}
	.content-item {
		margin-top: 48px;

		&__content .title {
			font-size: $font-size-xl;
			font-weight: 700;
			text-align: center;
			text-transform: capitalize;
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
	.about-me {
		margin-top: 0;

		&__background {
			width: 100%;
			font-size: $font-size-xxl;
			height: fit-content;
			margin: 16px 0 24px 0;
			color: $text-color;
		}
		&__perfil {
			.card_perfil {
				flex-direction: column;
				align-items: center;

				&__image {
					width: 200px;
				}

				&__text {
					width: 100%;
					text-align: center;
					text-wrap: balance;
					padding: 0 8px;
					box-sizing: border-box;
				}
			}
			.about-me__counters {
				flex-direction: column;
				align-items: center;
			}
		}

		&__social {
			flex-direction: column;
			.social-element {
				flex-direction: column;
			}
			img {
				width: 50px;
			}
			span {
				font-size: $font-size-m;
			}
		}

		&__graph {
			max-width: 100%;
		}
	}
}
@include screens(phone) {
}
