@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.main_category {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding-left: 24px;
	padding-right: 24px;

	&__folders {
		// background-color: #f00;
		width: 100%;
		height: fit-content;
		margin-top: 24px;

		.folder-section {
			margin-bottom: 32px;

			&__title {
				font-size: $font-size-s;
				color: $grey;
				margin-bottom: 8px;
				text-transform: uppercase;
			}

			&__folder {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				// justify-content: center;
				gap: 16px;
			}
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
	.main_category {
		.folder-section {
			&__title {
				text-align: center;
				font-size: $font-size-m;
				margin-bottom: 12px;
			}

			&__folder {
				margin-top: 20px;
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
