@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.text-card {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;

	&.text-at-dx {
		flex-flow: row-reverse;

		.text-card__text {
			text-align: start;
		}
	}

	&__card,
	&__text {
		width: 50%;
		margin: 0 16px;
		box-sizing: border-box;
	}

	&__card {
		border-radius: 10px;
		overflow: hidden;
		max-height: 300px;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	&__text {
		text-align: end;
		// padding: 0 16px;

		.text-card__title {
			font-size: $font-size-m;
			font-weight: 600;
			text-transform: capitalize;

			&:has(+ .text-card__subtitle) {
				+ .text-card__subtitle {
					margin-bottom: 16px;
				}
			}
		}
		.text-card__subtitle {
			font-size: $font-size-s;
			color: $grey;
		}
		.text-card__text-content {
			font-size: $font-size-m;
			@include max-lines(8);
		}
	}

	.button.button--primary {
		padding: 6px 12px;
		font-size: $font-size-s;
		margin-top: 16px;
	}
}

@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
	.text-card {
		flex-direction: column-reverse;
		align-items: center;

		&.text-at-dx {
			flex-direction: column-reverse;
		}

		&__card,
		&__text {
			width: calc(100% - 10px);
			margin: 0;
			text-align: start;
		}

		&__text {
			margin-top: 24px;
		}

		.text-card__text .text-card__text-content {
			@include max-lines(3);
		}
	}
}
