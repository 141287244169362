@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.button {
	position: relative;
	display: inline-flex;
	padding: 8px 16px;
	color: $base-color-light;
	border-radius: 24px;
	font-size: $font-size-m;
	font-weight: 500;
	transition: all 0.5s;
	border: none;
	backdrop-filter: blur(5px);
	background-color: rgba($color: $base-color-light, $alpha: 0.3);

	&:hover {
		cursor: pointer;
		// color: currentColor !important;
	}

	&.button--primary {
		border: 1px solid $base-color-light;
		color: $text-color;

		&:hover {
			background-color: rgba(255, 255, 255, 0.4);
			color: currentColor !important;
		}
	}
	&.button--secondary {
		color: $text-color;
		background-color: rgba($color: $text-color-dark, $alpha: 1);

		&:hover {
			background-color: rgba($color: $text-color-dark, $alpha: 0.7);
		}
	}
	&.button--nat {
		background-color: rgba(255, 255, 255, 0.9);
		color: $text-color-dark !important;

		&:hover {
			background-color: rgba(255, 255, 255, 0.5);
			color: $text-color !important;
		}

		span:has(.button-leaf) {
			width: 18px;
			position: absolute;

			&:nth-child(1) {
				transform: translate(-100%, -10px);
			}
			&:nth-child(2) {
				transform: scale(-0.7, 0.8) translate(0, -14px);
			}
			&:nth-child(3) {
				transform: scale(-0.7, 0.8) translate(-70px, -14px);
			}
			&:nth-child(4) {
				transform: scale(0.8, 1) translate(-40.5px, 10px);
			}
		}

		.button-leaf {
			width: 100%;
			transform-origin: right top;

			&:nth-child(1) {
				animation: wind infinite ease-in-out 4s;
			}
			&:nth-child(2) {
				animation: wind infinite ease-in-out 9s alternate-reverse;
			}
			&:nth-child(3) {
				animation: wind infinite ease-in-out 6s;
			}
			&:nth-child(4) {
				animation: wind infinite ease-in-out 5s alternate-reverse;
			}
		}

		@keyframes wind {
			0% {
				transform: rotate(0);
			}
			50% {
				transform: rotate(-60deg);
			}
			100% {
				transform: rotate(0);
			}
		}
	}
}
