//COLOR
$base-color-dark: #111;
$base-color-light: #fbfbfb;
$grey: #adada7;
$grey2: #73736f;
$background-color-dark: rgba($base-color-dark, 0.6);
$background-color-light: rgba($base-color-light, 0.7);
$background-color-transparent: rgba(0, 0, 0, 0);
$red: #c1121f;
$green: #38b000;
$light-green: #70e000;
$yellow: #ffba08;
$orange: #ff8500;
$purple: #9d4edd;
$blue: #4895ef;
$light-blue: #48cae4;
$light-blue-plus: #00b4d8;
$light-blue-min: #ade8f4;

@mixin bg-gradient-maker($color1, $color2, $direction) {
	background: linear-gradient($direction, $color1, $color2);
}

$backdrop-filter-px: 7px;

//TEXT
$text-color: #fff;
$text-color-dark: #111;

$font-size-xs: 10px;
$font-size-s: 13px;
$font-size-m: 16px;
$font-size-l: 19px;
$font-size-xl: 21px;
$font-size-xxl: 24px;
$font-size-xxxl: 32px;

$font-size-title: 100px;
$font-size-subtitle: 70px;

$font-family-title: "Red Hat Display", sans-serif;
$font-family-body: "Inter", sans-serif;

//
$margin-to-border: 20;
$second-margin-to-border: 100px;

$hambuger-width: 25;
// $logo-size: 50px;
$logo-size: 40px;
$header-heigth: $logo-size + 10px;

$nav-link-width: 200;

$border-radius: 15px;
