@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.content-item__content:has(.card-slider-container) {
	width: calc(100% - 20px) !important;
	padding: 0 10px;
}
.card-slider-container {
	height: fit-content;
	display: flex;
	flex-direction: column;
	width: 100%;

	.slider-content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		overflow-x: scroll;
		gap: 12px;
		padding: 10px 0;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.card-slider {
		--pathImgName: url("../../images/placeholder.png");

		width: 30%;
		height: 250px;
		border-radius: 10px;
		overflow: hidden;
		flex-shrink: 0;
		position: sticky;
		left: 0;

		background-image: var(--pathImgName);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		box-shadow: -6px 0px 20px -3px rgba(29, 29, 29, 0.82);

		transition: all 0.5s;

		& span {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: $font-size-xl;
			text-transform: capitalize;
			transition: all 0.5s;
		}

		&:hover {
			// width: 300px;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				width: fit-content;
				height: fit-content;
				padding: 4px 8px;
				background-color: rgba(0, 0, 0, 0);
				border-radius: 8px;
				backdrop-filter: blur(0px);
				color: #fff;
			}
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.slider-points {
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 8px;
		margin-top: 10px;

		&__point {
			width: 10px;
			height: 10px;
			border-radius: 20px;
			background-color: $base-color-light;
			transition: all 0.5s;

			&:hover {
				cursor: pointer;
				background-color: $grey;
			}
		}
	}
}

@include screens(tablet) {
	.card-slider-container {
		.slider-content {
			.card-slider {
				width: 35%;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					width: 100%;
					height: fit-content;
					padding: 4px 8px;
					background-color: rgba(0, 0, 0, 0.7);
					font-size: $font-size-l;
					color: #fff;
				}
			}
		}

		.slider-points {
			gap: 10px;
			&__point {
				width: 8px;
				height: 8px;
				border-radius: 20px;
			}
		}
	}
}
@include screens(phone) {
	.card-slider-container {
		.card-slider {
			width: 45%;
		}
	}
}
