@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.link_logo {
	width: auto;
	height: auto;

	.logo_image {
		width: inherit;
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
}
