@import "../../../scss/variable.scss";
@import "../../../scss/_mixins.scss";

.card-info {
	width: 250px;
	height: 300px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	&__image {
		--backgound: url("../../../images/placeholder.png");

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-image: var(--backgound);
		background-position: center center;
		background-size: cover;
	}

	&__data {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		gap: 12px;
		background: linear-gradient(to top, #111111b3 40%, transparent);
		z-index: 2;
		font-size: $font-size-s;
		padding: 4px;
		box-sizing: border-box;

		.data__alt-category {
			position: absolute;
			top: 4px;
			right: 4px;
			background-color: $background-color-dark;
			backdrop-filter: blur(5px);
			color: $text-color;
			padding: 4px 8px;
			border-radius: 8px;
			text-transform: capitalize;

			&.natura {
				background-color: rgba(11, 227, 108, 0.5);
			}
			&.paesaggi {
				background-color: rgba(11, 198, 227, 0.5);
			}
			&.varie {
				background-color: rgba(227, 173, 11, 0.5);
			}
			&.persone {
				background-color: rgba(170, 21, 207, 0.5);
			}
		}

		.data__position {
			font-size: $font-size-m;
			text-transform: capitalize;
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
}
