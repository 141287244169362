@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.breadcrumb-container {
	--distance-between: 8px;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--distance-between);

	.breadcrumb__element {
		display: flex;
		flex-direction: row;
		align-items: center;

		.breadcrumb__icon {
			span:first-child {
				&::before {
					content: "home";
				}
			}
		}
		.breadcrumb__text {
			text-transform: capitalize;
			&::before {
				padding-right: var(--distance-between);
				content: "/";
			}
		}
	}
}
