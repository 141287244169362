@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.icon-component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;

	.material-symbols-outlined {
		cursor: default;
		transition: all 0.32s;
		font-variation-settings: "FILL" 1, "wght" 100;

		&.linker:hover {
			cursor: pointer;
			// color: $grey;
		}

		&.no-fill {
			font-variation-settings: "FILL" 0;
		}
	}

	&__subtitle {
		width: 100%;
		text-align: center;
		padding: 0 0 8px 0;
	}
}
