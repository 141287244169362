@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.masonry-grid {
	position: relative;
	width: 100%;
	height: auto;
	column-count: 4;
	column-gap: 12px;

	.masonry-card {
		display: grid;
		grid-template-rows: 1fr auto;
		break-inside: avoid;
		margin-bottom: 12px;
		position: relative;

		img {
			// cursor: zoom-in;
			border-radius: 2px;
		}

		&__tags {
			position: absolute;
			bottom: 4px;
			right: 4px;
			padding: 2px 8px;
			background-color: rgba(255, 255, 255, 0.6);
			backdrop-filter: blur(5px);
			text-transform: capitalize;
			color: #222;
			border-radius: 4px;
			font-size: 12px;
		}
	}

	#photoModal {
		opacity: 0;
		visibility: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.9);
		backdrop-filter: blur(5px);
		top: 0;
		left: 0;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: zoom-out;

		img {
			z-index: 1000;
			border-radius: 2px;
		}
		.ver-img {
			width: auto;
			height: 80%;
		}
		.hor-img {
			width: 80%;
			height: auto;
		}
	}
}

@include screens(large-desktop) {
	.masonry-grid {
		column-count: 3;

		#photoModal {
			#div-photo {
				max-width: 95%;
				max-height: 95%;
			}
		}
	}
}
@include screens(desktop) {
	.masonry-grid {
		#photoModal {
			.ver-img {
				width: 80%;
				height: auto;
			}
		}
	}
}

@include screens(tablet) {
	.masonry-grid {
		column-count: 2;

		#photoModal {
			.ver-img {
				width: 75%;
				height: auto;
			}
		}
	}
}
@include screens(phone) {
	.masonry-grid {
		column-count: 1;

		#photoModal {
			#div-photo {
				max-width: 95%;
				max-height: 95%;
			}

			.ver-img,
			.hor-img {
				width: 90%;
				height: auto;
			}
		}
	}
}

@media all and (orientation: landscape) {
	.masonry-grid {
		#photoModal {
			.ver-img {
				height: 80%;
				width: auto;
			}
			.hor-img {
				width: 90%;
				height: auto;
			}
		}
	}
}
