@import "../../../scss/variable.scss";
@import "../../../scss/_mixins.scss";

.triptych.container {
	width: 100%;
	background-color: $base-color-light;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 16px 16px;
	gap: 32px;
	box-sizing: border-box;

	&.dark {
		background-color: $base-color-dark;

		.container {
			&__header {
				color: $text-color;

				.container__subtitle {
					color: $grey;
				}
			}
		}
	}

	&.reverse {
		flex-direction: row-reverse;
		.container {
			&__header {
				text-align: right;
			}
		}
	}

	> div {
		flex: 1 1 0;
	}

	.container {
		&__header {
			color: $text-color-dark;

			.container__title {
				font-size: $font-size-xl;
				font-weight: 600;
			}
			.container__subtitle {
				font-size: $font-size-s;
				color: $grey2;
			}
			.container__text {
				margin-top: 24px;
			}
			.container__buttons {
				margin-top: 24px;

				.button {
					font-size: $font-size-s;
					padding: 6px 10px;
				}
			}
		}

		&__cards {
			display: flex;
			flex-direction: row;
			flex-grow: 3;
			gap: 8px;

			.cards_card {
				width: 33%;
				min-height: 380px;
				border-radius: 10px;
				overflow: hidden;

				img {
					height: 100%;
					object-fit: cover;
					transition: all 0.5s;

					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}

@include screens(tablet) {
	.triptych.container {
		padding-top: 32px;
		flex-direction: column-reverse;

		&:first-child {
			padding-top: 16px;
		}

		&.reverse {
			flex-direction: column-reverse;
			
			.container {
				&__header {
					text-align: inherit;
				}
			}
		}
		.container {
			&__cards {
				.cards_card {
					width: 100%;
					min-height: auto;
					max-height: 270px;
					display: none;

					&:nth-child(1) {
						display: block;
					}
				}
			}
		}
	}
}
