@import "../../../scss/variable.scss";
@import "../../../scss/_mixins.scss";

.card_middle {
	--backgound: url("../../../images/placeholder.png");

	position: relative;
	width: 220px;
	height: 220px;
	border-radius: 8px;
	background-color: $base-color-dark;
	background-image: linear-gradient(to top, #222222a3 20%, transparent 50%), var(--backgound);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	padding: 8px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	transition: all 0.3s;
	box-sizing: border-box;

	&__titles {
		.title {
			font-size: $font-size-m;
			text-transform: capitalize;
			@include max-lines(1);
		}
		.subtitle {
			font-size: $font-size-s;
			color: $grey;
			@include max-lines(1);
			text-transform: lowercase;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}

a:has(> .card_middle) {
	&:hover {
		color: #fff !important;
	}
}

@include screens(tablet) {
	.card_middle {
		width: 180px;
		height: 230px;
	}
}
@include screens(phone) {
	.card_middle {
		width: 270px;
		height: 160px;
	}
}
