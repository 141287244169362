@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.tabs {
	position: sticky;
	top: $header-heigth;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	width: 100%;
	gap: 16px;
	background-color: $background-color-dark;
	backdrop-filter: blur(5px);
	padding: 10px 0;
	text-transform: capitalize;
	font-size: $font-size-s;
	overflow-x: scroll;
	z-index: 999;

	&::-webkit-scrollbar {
		display: none;
	}

	&__element {
		padding: 3px 6px;
		border-radius: 4px;
		transition: all 0.3s;
		color: $grey;
		cursor: pointer;

		&.active {
			background-color: $text-color;
			color: $text-color-dark;
		}

		&:hover:not(.active) {
			color: $text-color;
		}
	}
	&__icon {
		display: none;
	}

	&__mobile {
		display: none;
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
	.tabs {
		top: 0;
		gap: 12px;

		&__element {
			padding: 4px 8px;
			min-width: fit-content;
		}
		&__element.active {
			color: $text-color-dark;
			border-radius: 30px;
		}

		&::-webkit-scrollbar {
			display: block;
			height: 1.5px;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: $grey;
			border-radius: 20px;
		}

		// &:has(.tabs__element:nth-last-child.active) {
		// 	.tabs__arrow:last-child {
		// 		display: none !important;
		// 	}
		// }

		// &__icon {
		// 	display: inline-block;
		// 	transition: all 0.3s;

		// 	*.rotate {
		// 		transform: rotate(180deg);
		// 	}
		// }

		// &__mobile {
		// 	display: flex;
		// 	flex-direction: row;
		// 	position: fixed;
		// 	z-index: 999;
		// 	background-color: $base-color-dark;
		// 	align-items: center;
		// 	padding: 4px 8px;
		// 	border-radius: 8px;

		// 	.tabs__active-element {
		// 		margin-right: 8px;
		// 	}
		// }
		// &__modal {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	background-color: $background-color-dark;
		// 	backdrop-filter: blur(5px);
		// 	padding: 8px;
		// 	width: fit-content;
		// 	min-width: 130px;
		// 	border-radius: 8px;
		// 	opacity: 0;
		// 	visibility: hidden;
		// 	transition: all 0.3s;

		// 	&.show {
		// 		opacity: 1;
		// 		visibility: visible;
		// 		transform: translateX(50%);
		// 	}

		// 	.modal__element {
		// 		padding: 0 8px 16px 8px;

		// 		&:last-child {
		// 			padding-bottom: 0;
		// 		}
		// 	}
		// }
	}
}
