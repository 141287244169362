@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.view-last-row {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.view-title {
		width: 100%;
		height: 20%;
		font-size: 18px;
	}
	.view-rows {
		width: 100%;
		height: 80%;
	}
}
.admin-panel .container {
	height: auto;

	button {
		padding: 4px;
		border-radius: 8px;
		border: 1px solid #333;
		transition: all 0.5s;

		&:hover {
			background-color: #eee;
			color: #333;
			cursor: pointer;
			transform: translateY(-2px);
		}
	}
	.btn-copy {
		background-color: #47ea4f;
	}
}

.card-set-image {
	width: fit-content;
	height: fit-content;
	backdrop-filter: blur(5px);
	background-color: antiquewhite;
	border-radius: $border-radius;
	padding: 10px;
	color: black;
	display: flex;
	flex-direction: row;

	.set-image-data {
		display: flex;
		flex-direction: row;
	}

	.image-data {
		width: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		margin-right: 8px;

		input,
		textarea,
		select {
			margin-bottom: 8px;
			border-radius: 8px;
			border: 1px solid #000;
			padding: 4px;
		}
	}

	.image-image {
		width: 300px;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.div-json-object {
	display: none;
	height: fit-content;
	width: 100%;
	box-sizing: border-box;
	padding: 8px;
	font-family: "Courier New", Courier, monospace;
	p {
		padding: 8px;
		background-color: #fefefe;
		color: #000;
		height: 200px;
		overflow: scroll;
	}
}

.admin-table {
	width: 100%;
	height: auto;

	table {
		width: 100%;
		height: auto;
		border: 1px solid white;
		border-collapse: collapse;

		thead {
			position: sticky;
			top: $header-heigth;
			background-color: #aaa;
			color: #000;
		}

		img {
			width: 80px;
		}

		tr:nth-child(even) {
			background-color: #333;
		}
		td {
		}
	}
}

.admin-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 8px;
	gap: 16px;
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
}
@include screens(phone) {
}
