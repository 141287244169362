@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";
#home-main {
	height: fit-content;
	// height: calc(100vh - $header-heigth);
	margin-top: $header-heigth;
	position: relative;
	box-sizing: border-box;

	.home-main__text {
		width: 100%;
		height: 100vh;
		position: relative;
		display: flex;
		flex-direction: column;
		opacity: 0;
		animation: appearOpacity 1s ease-in-out forwards;
		animation-delay: 0.5s;
		align-items: center;
		justify-content: center;

		@keyframes appearOpacity {
			to {
				opacity: 1;
			}
		}

		&__title {
			font-size: clamp(2em, 8vw, 7rem);
			font-weight: 600;
			text-transform: uppercase;
			position: sticky;
			top: 60px;
			padding-bottom: 10px;
			box-sizing: border-box;

			span {
				animation: roulette 2s ease-in-out;
				animation-delay: 1s;
				animation-fill-mode: forwards;

				@keyframes roulette {
					20% {
						padding: 20px;
					}

					100% {
						padding: 0px;
					}
				}
			}
		}
		&__subtitle {
			font-size: clamp(1rem, 3vw, 3rem);
			position: relative;
			color: $grey;

			span {
				position: relative;

				&:nth-child(1) {
					opacity: 0;

					animation: showOpacity 2s ease-in-out forwards 2s;

					@keyframes showOpacity {
						to {
							opacity: 1;
						}
					}
				}

				&:nth-child(2) {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 0;
					background-color: #ffb510;

					animation: slideWordDiv 2s ease-in-out forwards 2s;

					@keyframes slideWordDiv {
						50% {
							width: 105%;
							left: 0;
						}
						100% {
							width: 0;
							left: 105%;
						}
					}
				}
			}
		}

		&__discover {
			margin-top: 35px;
			z-index: 9999;
		}
	}
	.home-main__content {
		position: relative;
		width: 100%;
		min-height: 100vh;
		// background-color: rgba(0, 0, 0, 0.3);
		background: linear-gradient(rgba(0, 0, 0, 0.3), transparent);
		backdrop-filter: blur(25px);
		// margin-top: calc($header-heigth);
		display: flex;
		flex-direction: column;

		.content-item {
			width: 100%;

			&:not(:first-child) {
				margin-top: 40px;
			}

			&:first-child {
				margin-top: calc($header-heigth + 8px);
			}

			&.centered {
				display: flex;
				align-items: center;
				flex-direction: column;
			}
			&__content {
				margin-top: 32px;
				max-width: 1400px;
				width: 100%;
			}

			.cards {
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				gap: 10px;

				.single-card {
					width: calc(25% - 10px);
					height: 250px;
					border-radius: 10px;
					position: relative;
					overflow: hidden;
					text-transform: capitalize;

					> img {
						height: 100%;
						object-fit: cover;
					}

					&__text {
						position: absolute;
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						transition: all 0.5s;
						background-color: rgba(0, 0, 0, 0.4);

						.single-card__subtitle {
							text-transform: none;
						}

						&:hover {
							backdrop-filter: blur(3px);
							cursor: pointer;

							.single-card__subtitle {
								opacity: 1;
							}
						}
					}
					&__title {
						font-size: $font-size-xxl;
						color: #fff;
					}
					&__subtitle {
						margin-top: 10px;
						font-size: $font-size-m;
						color: rgb(234, 234, 234);
						opacity: 0;
						transition: all 0.5s;
						text-wrap: balance;
					}
				}
			}
		}
	}
}

.home-main__bg {
	position: fixed;
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../images/sfondoHome_min.jpeg");
}

.home-first-text {
	text-wrap: balance;
}
.photography-grid {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9999;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	animation: disappear 2.5s ease-in-out forwards;

	@keyframes disappear {
		100% {
			display: none;
		}
	}
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		overflow: hidden;

		@keyframes animationOpacity {
			0% {
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}

		&:nth-child(1n + 0) {
			border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
			border-right: 0.5px solid rgba(255, 255, 255, 0.4);
		}
		&:nth-child(1n + 7) {
			border-bottom: 0;
		}
		&:nth-child(3n + 3) {
			border-right: 0;
		}

		img {
			object-fit: cover;
			height: 100%;
		}

		&:nth-child(1),
		&:nth-child(3),
		&:nth-child(8) {
			animation: animationOpacity 2s ease-in-out forwards;
		}
		&:nth-child(2),
		&:nth-child(4),
		&:nth-child(6) {
			animation: animationOpacity 3s ease-in-out forwards;
		}
		&:nth-child(5),
		&:nth-child(7),
		&:nth-child(9) {
			animation: animationOpacity 1.5s ease-in-out forwards;
		}
	}
}
.content-item__content.folder-categories {
	display: flex;
	flex-direction: row;
	transition: all 0.3s;
	gap: 24px;

	span::before {
		content: "folder";
	}

	a:hover {
		color: currentColor !important;
	}

	span:hover {
		&::before {
			content: "folder_open";
		}
	}
}

// .home-first-section {
// 	display: flex;
// 	flex-direction: row;
// 	width: 100%;
// 	height: fit-content;

// 	&__ring {
// 		// background-color: red;
// 		padding-right: 16px;
// 	}
// 	&__grid {
// 		// background-color: blue;
// 		height: fit-content;
// 	}
// }

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
	#home-main {
		margin-top: 0;

		.home-main__content .content-item {
			#first-title {
				display: none;
			}

			&:first-child {
				margin-top: 0;
			}
			.cards .single-card {
				width: calc(50% - 10px);
			}
		}
	}

	.content-item:has(.folder-categories) {
		display: none !important;
	}
}
@include screens(phone) {
	#home-main .home-main__content .content-item {
		.cards .single-card {
			width: calc(100% - 10px);
		}
	}
}
