@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

#header {
	width: 100%;
	height: fit-content;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	transition: all 0.4s;
	background-color: rgba(11, 11, 11, 1);
	// backdrop-filter: blur(15px);
	padding: var(--headerPadding);
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;

	.link_logo {
		z-index: 1001;
		height: fit-content;

		.logo_image {
			width: $logo-size;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	a.selected {
		border-left: 3px solid #73eece;
		padding-left: 6px;
	}

	#menu_settings {
		display: none;
	}

	#nav_link::-webkit-scrollbar {
		display: none;
	}
}

#menu_opener {
	position: absolute;
	left: 20px;
	display: flex;
	flex-direction: column;
	padding: 0;
	z-index: 1001;
	transition: all 0.5s;
	height: 100%;
	top: 0;
	justify-content: center;

	.menu_opener_line {
		transition: all 0.5s;
		width: $hambuger-width + px;
		height: 2px;
		background-color: $base-color-light;
		margin: 3px 0;
	}

	&:hover {
		cursor: pointer;

		& .menu_opener_line {
			background-color: #a4a4a4;
		}
	}

	&.open {
		transform: translateX(calc(300px - 34px * 2));
		padding-top: 12px;
		#line1 {
			transform: translate(0px, 4px) rotate(45deg);
		}
		#line2 {
			transform: translate(0px, -4px) rotate(-45deg);
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
	#menu_opener {
		left: unset;
		right: 20px;

		&.open {
			transform: unset;
			padding-top: unset;
		}
	}

	#header #menu_settings {
		position: absolute;
		top: 0;
		left: 20px;
		display: flex;
		height: 100%;
		transition: all 0.7s;

		&.open {
			transform: rotate(180deg);
		}
	}

	#header {
		--headerWidth: 85%;
		top: unset;
		bottom: 10px;

		width: var(--headerWidth);
		left: calc((100% - var(--headerWidth)) / 2);
		border-radius: 30px;
	}
}
@include screens(phone) {
}
