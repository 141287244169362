@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.div-search {
	position: fixed;
	top: 10px;
	right: 20px;
	width: 200px;
	height: fit-content;
	z-index: 999;
}

.empty-photos {
	height: fit-content;
	min-height: 250px;

	h2,
	&__subtitle {
		padding-left: 8px;
		box-sizing: border-box;
	}

	h2 {
		text-wrap: balance;
	}

	&__subtitle {
		display: flex;
		align-items: center;

		> span {
			width: 200px;
			margin-left: 16px;
		}
	}
}

@include screens(larger-desktop) {
}
@include screens(large-desktop) {
}
@include screens(desktop) {
}
@include screens(tablet) {
	.div-search {
		display: none;
	}
	.empty-photos {
		min-height: 100vh;

		h2,
		&__subtitle {
			padding-left: 0;
		}

		h2 {
			text-align: center;
		}
		&__subtitle {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;

			> span {
				width: 80%;
				margin: 0;
			}
		}
	}
}
@include screens(phone) {
}
