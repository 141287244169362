@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.policy-banner {
	position: fixed;
	bottom: 16px;
	left: 16px;
	width: calc(100% - 32px);
	height: fit-content;
	z-index: 99999;
	background-color: $background-color-light;
	color: $text-color-dark;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	backdrop-filter: blur(10px);
	opacity: 0;

	animation: opacityDelayed ease-in-out forwards 1s;
	animation-delay: 2.5s;

	@keyframes opacityDelayed {
		to {
			opacity: 1;
		}
	}

	&__title {
		font-size: $font-size-l;
		font-weight: 700;
	}

	&__text {
		font-size: $font-size-s;

		a {
			color: currentColor !important;
			text-decoration: underline;

			&:hover {
				color: $text-color !important;
			}
		}
	}
	.text__read-more {
		display: none;
	}
}

@include screens(phone) {
	.policy-banner {
		.policy-banner__text {
			@include max-lines(4);

			&.expand {
				@include max-lines(999999);
			}
		}
		.text__read-more {
			display: block;
			font-size: $font-size-s;
			margin-top: 4px;
			text-decoration: underline;
		}

		.policy-banner__btn {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 8px;
		}
	}
}
