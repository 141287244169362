@import "../../scss/variable.scss";
@import "../../scss/_mixins.scss";

.counter-container {
	--colorInUse: #fff;

	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.5s;

	&__circle {
		width: 30px;
		height: 30px;
		padding: 16px;
		border: 5px solid #fff;
		border-radius: 50%;
		font-weight: 600;
		font-size: $font-size-l;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__text {
		margin-top: 12px;
		font-size: $font-size-s;
	}

	&--red {
		--colorInUse: #c1121f;
	}
	&--green {
		--colorInUse: #38b000;
	}
	&--light-green {
		--colorInUse: #70e000;
	}
	&--yellow {
		--colorInUse: #ffba08;
	}
	&--orange {
		--colorInUse: #ff8500;
	}
	&--purple {
		--colorInUse: #9d4edd;
	}
	&--blue {
		--colorInUse: #4895ef;
	}
	&--light-blue {
		--colorInUse: #48cae4;
	}
	&--light-blue-plus {
		--colorInUse: #00b4d8;
	}
	&--light-blue-min {
		--colorInUse: #ade8f4;
	}

	&__circle,
	&__text {
		border-color: var(--colorInUse);
		color: var(--colorInUse);
	}
}
