// Media Queries breakpoints
//
// $breakpoint
// - phone: 576px;
// - tablet: 768px;
// - desktop: 992px;
// - large-desktop: 1200px;
// - larger-desktop: 1400px;

@mixin max-lines($number) {
	display: -webkit-box;
	-webkit-line-clamp: $number !important;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin screens($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 576px) {
			@content;
		}
	}
	@if $breakpoint == tablet {
		@media only screen and (max-width: 768px) {
			@content;
		}
	}
	@if $breakpoint == desktop {
		@media only screen and (max-width: 992px) {
			@content;
		}
	}
	@if $breakpoint == large-desktop {
		@media only screen and (max-width: 1200px) {
			@content;
		}
	}
	@if $breakpoint == larger-desktop {
		@media only screen and (max-width: 1400px) {
			@content;
		}
	}
}
